export const youTubeLinks = {
  0: [
    "https://www.youtube.com/embed/x3aogIZfVUI",
    "https://www.youtube.com/embed/xsqh63FaYz4"
    // "https://www.youtube.com/embed/8oQ-WNJoYtM"
    // "https://www.youtube.com/embed/3SpPraOLJl4",
    // "https://www.youtube.com/embed/WjoBQsoekgk",
    // "https://www.youtube.com/embed/TYMGYumHKtw",
    // "https://www.youtube.com/embed/tYyNM9pmOlA",
    // "https://www.youtube.com/embed/QNAOIXhNRJs",
    // "https://www.youtube.com/embed/Ba3qZjzPonI",
    // "https://www.youtube.com/embed/-irx3_FA2nU"
  ],
  1: [
    "https://www.youtube.com/embed/fKMGZW8cA1U",
    "https://www.youtube.com/embed/E4tiWhsQhQg"
    // "https://www.youtube.com/embed/DbjMVBeBBdQ"
    // "https://www.youtube.com/embed/BS7XkoGmhXQ",
    // "https://www.youtube.com/embed/VUG7m-IyoZ8",
    // "https://www.youtube.com/embed/EJK4RuUz0yI",
    // "https://www.youtube.com/embed/_Xm3GEDVmbM",
    // "https://www.youtube.com/embed/nNe1p2uPt_g",
    // "https://www.youtube.com/embed/6dhO7S7_DW0",
    // "https://www.youtube.com/embed/CSXksjKzR2w"
  ],
  2: [
    "https://www.youtube.com/embed/u3h_Yr40rhg",
    "https://www.youtube.com/embed/fgveacDs6_c"
    // "https://www.youtube.com/embed/8DGcqYVxJLQ"
    // "https://www.youtube.com/embed/qg39x20q790",
    // "https://www.youtube.com/embed/4iy4yEKa7W8",
    // "https://www.youtube.com/embed/f7Msw169kr4",
    // "https://www.youtube.com/embed/qULTwquOuT4",
    // "https://www.youtube.com/embed/cdsJB1gHTiA",
    // "https://www.youtube.com/embed/ESkI_WR1qqc",
    // "https://www.youtube.com/embed/v5cO0Bs-Tlg"
  ],
  3: [
    "https://www.youtube.com/embed/TFW8Orq7rX8",
    "https://www.youtube.com/embed/6I7aSEZGJPM"
    // "https://www.youtube.com/embed/GtLHr4dilUE"
    // "https://www.youtube.com/embed/LcrhDiRHOLI",
    // "https://www.youtube.com/embed/f-2YscGQsMw",
    // "https://www.youtube.com/embed/Ki33wY5ZwI4",
    // "https://www.youtube.com/embed/Uij_PWAXncc",
    // "https://www.youtube.com/embed/x9r_j7Lgy48",
    // "https://www.youtube.com/embed/Zj82gmAR3bc",
    // "https://www.youtube.com/embed/jD-qEd2MUXw"
  ],
  4: [
    "https://www.youtube.com/embed/ghAhdn7xWCw",
    "https://www.youtube.com/embed/ACWvqXKsmDw"
  ],
  5: [
    "https://www.youtube.com/embed/EpRBZrtjpMM",
    "https://www.youtube.com/embed/_RHDEq3qvD0"
  ],
  6: [
    "https://www.youtube.com/embed/9JAR0GcL0kc",
    "https://www.youtube.com/embed/60T1eRQzlGw"
  ],
  7: [
    "https://www.youtube.com/embed/kuwF-Oi6Nt0",
    "https://www.youtube.com/embed/_59x2ayZvC4"
  ],
  8: [
    "https://www.youtube.com/embed/qDrPFgtRRkI",
    "https://www.youtube.com/embed/H73ipHamM84"
  ],
  9: [
    "https://www.youtube.com/embed/MYp8vNL9-wg",
    "https://www.youtube.com/embed/oXH2aDh8F3s"
  ],
  10: [
    "https://www.youtube.com/embed/ZWk19OVon2k",
    "https://www.youtube.com/embed/8DZktowZo_k"
  ],
  11: [
    "https://www.youtube.com/embed/MxNm6ynj_MU",
    "https://www.youtube.com/embed/9r1GT-IpCAs"
  ],
  12: [
    "https://www.youtube.com/embed/tZNO8gTo6Ic",
    "https://www.youtube.com/embed/gYhEjSkWdp4"
  ],
  13: [
    "https://www.youtube.com/embed/BZPeh6JU9tc",
    "https://www.youtube.com/embed/jr7wiGWz_xY"
  ],
  14: [
    "https://www.youtube.com/embed/DWG-Jb3DNVA",
    "https://www.youtube.com/embed/CirlwUzPYek"
  ],
  15: [
    "https://www.youtube.com/embed/oWUNkZrlkho",
    "https://www.youtube.com/embed/C6MyDJMddYE"
  ],
  16: [
    "https://www.youtube.com/embed/pcKpmLhkgrw",
    "https://www.youtube.com/embed/r_0zKhwLgyw"
  ],
  17: [
    "https://www.youtube.com/embed/CscxGprl1yw",
    "https://www.youtube.com/embed/XN3JB67-a_k"
  ],
  18: [
    "https://www.youtube.com/embed/8tq6BIrIaio",
    "https://www.youtube.com/embed/uiN48I_a4_c"
  ],
  19: [
    "https://www.youtube.com/embed/nZaTZkI2I0c",
    "https://www.youtube.com/embed/j5ZpcvtDp88"
  ],
  20: [
    "https://www.youtube.com/embed/gQ3EM0BXEoE",
    "https://www.youtube.com/embed/XRYs1th9kmw"
  ]
};
