export const timesToSpeak = {
  "5 seconds": { text: "5 seconds" },
  "8 seconds": { text: "8 seconds" },
  "10 seconds": { text: "10 seconds" },
  "15 seconds": { text: "15 seconds" },
  "20 seconds": { text: "20 seconds" },
  "25 seconds": { text: "25 seconds" },
  "30 seconds": { text: "30 seconds" },
  "45 seconds": { text: "45 seconds" },
  "60 seconds": { text: "60 seconds" },
  "1:15 seconds": { text: "1 minute 15 seconds" },
  "1:30 seconds": { text: "1 minute 30 seconds" },
  "1:45 seconds": { text: "1 minute45 seconds" },
  "2 minutes": { text: "2 minutes" },
  "2:15 seconds": { text: "2 minutes15 seconds" },
  "2:30 seconds": { text: "2 minute 30 seconds" },
  "2:45 seconds": { text: "2 minutes 45 seconds" },
  "3 minutes": { text: "3 minutes" },
  "3:15 seconds": { text: "3 minutes 15 seconds" },
  "3:30 seconds": { text: "3 minutes 30 seconds" },
  "3:45 seconds": { text: "3 minutes 45 seconds" },
  "4 minutes": { text: "4 minutes" },
  "4:15 seconds": { text: "4 minutes 15 seconds" },
  "4:30 seconds": { text: "4 minutes 30 seconds" },
  "4:45 seconds": { text: "4 minutes 45 seconds" },
  "5 minutes": { text: "5 minutes" },
  "5:15 seconds": { text: "5 minutes 15 seconds" },
  "5:30 seconds": { text: "5 minutes 30 seconds" },
  "5:45 seconds": { text: "5 minutes 45 seconds" },
  "6 minutes": { text: "6 minutes" },
  "6:15 seconds": { text: "6 minutes 15 seconds" },
  "6:30 seconds": { text: "6 minutes 30 seconds" },
  "6:45 seconds": { text: "6 minutes 45 seconds" },
  "7 minutes": { text: "7 minutes" },
  "7:15 seconds": { text: "7 minutes 15 seconds" },
  "7:30 seconds": { text: "7 minutes 30 seconds" },
  "7:45 seconds": { text: "7 minutes 45 seconds" },
  "8 minutes": { text: "8 minutes" },
  "8:15 seconds": { text: "8 minutes 15 seconds" },
  "8:30 seconds": { text: "8 minutes 30 seconds" },
  "8:45 seconds": { text: "8 minutes 45 seconds" },
  "9 minutes": { text: "9 minutes" },
  "9:15 seconds": { text: "9 minutes 15 seconds" },
  "9:30 seconds": { text: "9 minutes 30 seconds" },
  "9:45 seconds": { text: "9 minutes 45 seconds" },
  "10 minutes": { text: "10 minutes" }
};
